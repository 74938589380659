var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-crud-layout',{attrs:{"search":_vm.searchQuery,"filters":_vm.filters},on:{"update:search":function($event){_vm.searchQuery=$event},"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.$t('approvals.pageTitle')))]},proxy:true},{key:"view.list",fn:function(){return [_c('k-crud-table',{ref:"table",attrs:{"headers":_vm.crudHeaders,"index-request":_vm.indexRequest,"search":_vm.searchQuery,"params":_vm.filters,"language-prefix":"approvals.crudHeaders","resource":"approvals"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var date = ref.item.date;
return [_c('span',[_vm._v(_vm._s(_vm.dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('LL')))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{name: 'approvals.show', params: {approvalId: item.id}}}},[_c('v-icon',[_vm._v("$visible")])],1)]}}])})]},proxy:true},{key:"filters",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('ApprovalsFilter',_vm._g(_vm._b({},'ApprovalsFilter',attrs,false),on))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }